import React from "react";
import PriceCalculationButton from "../PriceCalculator/PriceCalculationButton";

const AlarmanlagenHero = () => (
    <div className="hero_alarm columns">
        <div className="column left">
            <h1 className="title">Unsere Alarmanlagen</h1>
            <div className="subtitle">
                <p>Sicher.</p>
                <p>Smart.</p>
                <p>Förderfähig.</p>
                <div className="cta-btn is-hidden-mobile">
                    <PriceCalculationButton />
                </div>
            </div>
            <div className="bottom only-mobile has-text-centered">
                <PriceCalculationButton />
            </div>
        </div>
        <div className="column right is-hidden-touch">
            <div className="columns">
                <div className="column app is-vertical-bottom is-narrow">
                    <img
                        src="/img/smart-home-und-smartphone.png"
                        alt="Smart Home und Smartphone"
                    />
                </div>
                <div className="column alarmanlage is-narrow">
                    <img
                        src="/img/jablotron-aussensirene.png"
                        alt="Jablotron Aussensirene"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default AlarmanlagenHero;
