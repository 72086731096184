import React from 'react';

const FieberKameras = ({city}) => {
    let citySlug = typeof city !== 'undefined' ? city.slug : '';
    return (
        <div className="section fieber-kameras">
            <h2 className="has-text-centered">Die Vorteile mit unseren Fieber-Kameras</h2> 
            <ul className="checkmark centered-content">
                <li>
                    <span className="top">Social Distancing dank kontaktloser Temperaturmessung</span>
                    <p>Die Fieber-Kameras verringern das Risiko einer Kreuzinfektion, da die Temperaturmessung ohne menschliche Interaktion erfolgt.</p>
                </li>
                <li>
                    <span className="top">Höchste Präzision</span>
                    <p>Unsere Fieber-Kameras messen die Körpertemperatur mit einer hohen Genauigkeit von +/- 0,3°C, so präzise, wie keine andere Kamera auf dem Markt.</p>
                </li>
                <li>
                    <span className="top">Maximale Effizienz für kleine & große Kontrollbereiche</span>
                    <p>Leistungsfähige Technik für eine Temperaturmessung von bis zu 3 Personen pro Sekunde - geeignet sowohl für kleine Zugangssysteme, als auch für große Bereiche mit hoher Personendichte.</p>
                </li>
                <li>
                    <span className="top">Geringe Fehlerquote in der Fieberdetektion</span>
                    <p>Durch Nutzung modernster KI-Technologie wird die Quote der Fehlmessungen auf ein Minimum gesenkt. Gehen Sie auf Nummer sicher!</p>
                </li>
                <li>
                    <span className="top">Vielseitige Anwendungsbereiche</span>
                    <p>Unsere Fieber-Kameras werden u.a. eingesetzt in Behörden, Alters- und Pflegeheimen, Schulen, Polizeieinrichtungen, Krankenhäuser und Ladengeschäften.</p>
                </li>
                <li>
                    <span className="top">Kosteneinsparung durch weniger Personal</span>
                    <p>Die rechtzeitige Identifizierung von infizierten Personen durch das integrierte Frühwarnsystem spart Ressourcen. Die händische Temperaturmessung entfällt.</p>
                </li>
            </ul>
        </div>
    );
};


export default FieberKameras;