import React from "react";
import DefaultHero from "../components/DefaultHero";
import ReactHtmlParser from "react-html-parser";
import SevenDaysBanner from "../components/SevenDaysBanner";
import FaceToFace from "../components/FaceToFace";
import Footer from "../components/Footer";
import OurFavorits from "../components/OurFavorits";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import Header from "../components/Header";
import OurTeam from "../components/OurTeam";

import Alarmanlagen from "../components/Alarmanlagen";
import { graphql } from "gatsby";
import AlarmanlagenHero from "../components/heros/AlarmanlagenHero";
import VideoueberwachungHero from "../components/heros/VideoueberwachungHero";
import BrandmeldeanlagenHero from "../components/heros/BrandmeldeanlagenHero";
import TuerschloesserHero from "../components/heros/TuerschloesserHero";
import Video from "../components/Video";
import Brandmeldeanlagen from "../components/Brandmeldeanlagen";
import Tuerschloesser from "../components/Tuerschloesser";
import OurFavoritsVideo from "../components/OurFavoritsVideo";
import QueryString from "../helper/QueryString";
import FieberKamerasHero from "../components/heros/FieberKamerasHero";
import FieberKameras from "../components/FieberKameras";
import EinlasskontrollsystemeHero from "../components/heros/EinlasskontrollsystemeHero";
import Einlasskontrollsysteme from "../components/Einlasskontrollsysteme";
import StringBuilder from "../helper/StringBuilder";

import {
    Brand,
    Tuerschloss,
    Fieberkamera,
    Einlasskontrolle,
} from "../components/PriceCalculator/elements/Constants";

export default ({ data, pageContext }) => {
    const { city } = pageContext;
    const post = data.markdownRemark;
    const heros = {
        Alarmanlagen: AlarmanlagenHero,
        Videoueberwachung: VideoueberwachungHero,
        Brandmeldeanlagen: BrandmeldeanlagenHero,
        Tuerschloesser: TuerschloesserHero,
        "Fieber-Kameras": FieberKamerasHero,
        Einlasskontrollsysteme: EinlasskontrollsystemeHero,
    };
    const contextData = {
        title: pageContext.title,
        image: "/img/hero/" + post.frontmatter.image,
        image768: "/img/hero/" + post.frontmatter.image768,
        image1024: "/img/hero/" + post.frontmatter.image1024,
        image1216: "/img/hero/" + post.frontmatter.image1216,
        image1408: "/img/hero/" + post.frontmatter.image1408,
        showPriceCalculator: false,
        defaultCssClass: "page-hero",
        showElement: React.createElement(heros[post.frontmatter.hero], city),
        city: city,
        noIndex: post.frontmatter.noIndex,
    };
    switch (pageContext.slug) {
        case "/brandmeldeanlagen/":
            contextData.product = Brand;
            break;

        case "/tuerschloesser/":
            contextData.product = Tuerschloss;
            break;

        case "/fieber-kameras/":
            contextData.product = Fieberkamera;
            break;

        case "/einlasskontrollsysteme/":
            contextData.product = Einlasskontrolle;
            break;

        default:
            break;
    }
    let stringBuilder = new StringBuilder(city, contextData.product);
    if (typeof city !== "undefined") {
        let query = new QueryString();
        query.setCity(city);
    }
    if (typeof city !== "undefined") {
        pageContext = {
            title: city.title.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            ogTitle: city.ogTitle.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            description: city.description.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            ogDescription: city.ogDescription.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            keywords: stringBuilder.getMetaDataProductName() + " " + city.name,
        };
    }
    const elements = {
        facetoface: FaceToFace,
        sevendaybanner: SevenDaysBanner,
        ourfavorits: OurFavorits,
        ourfavoritsvideo: OurFavoritsVideo,
        pricebutton: PriceCalculationButton,
        alarmanlagen: Alarmanlagen,
        video: Video,
        brandmeldeanlagen: Brandmeldeanlagen,
        tuerschloesser: Tuerschloesser,
        fieberkameras: FieberKameras,
        einlasskontrollsysteme: Einlasskontrollsysteme,
    };

    return (
        <div>
            <div>
                <Header
                    page={pageContext}
                    siteMetadata={data.site.siteMetadata}
                    contextData={contextData}
                />
                <DefaultHero context={contextData} />
                <div>
                    {ReactHtmlParser(post.html, {
                        transform(node, index) {
                            if (node.type === "tag") {
                                if (elements.hasOwnProperty(node.name)) {
                                    let paramenter = node.attribs;
                                    paramenter.context = contextData;
                                    paramenter.city = city;
                                    return React.createElement(
                                        elements[node.name],
                                        paramenter
                                    );
                                }
                            }
                        },
                    })}
                </div>
                <OurTeam />
                {pageContext.slug == "/brandmeldeanlagen/" ? (
                    <div className="mainSeoText">
                        <h2>Aufgaben einer Brandmeldeanlage </h2>
                        Eine Brandmeldeanlage hat in erster Linie die Aufgabe,
                        die Hilfe leistenden Behörden, wie z. B. die Feuerwehr,
                        auf entstehende Brände aufmerksam zu machen, Personen im
                        Gebäude zu benachrichtigen, Brandschutzeinrichtungen zu
                        aktivieren und der Feuerwehr einen schnellen und
                        gewaltfreien Zugang zum Gebäude zu ermöglichen. Es liegt
                        in der Hand des Benutzers, festzustellen, ob ein Brand
                        in seinem Anfangsstadium entdeckt wird oder ob die
                        Flammen bereits aus den Fenstern schlagen. Es könnte
                        sein, dass eine Person zwar ein Feuer bemerkt und sich
                        in Sicherheit bringen kann, in der Eile dann aber das
                        Handy im Schließfach, auf dem Arbeitsplatz oder an
                        anderer Stelle liegen lässt und somit keine unmittelbare
                        Mitteilung an die Feuerwehr erfolgt. Die Feuerwehr muss
                        sich möglicherweise einen Weg in das Gebäude bahnen und
                        viel Zeit aufwenden, um die Situation zu klären. Dieser
                        lange Zeitverlust kann dazu führen, dass sich das Feuer
                        auf andere Räume oder Stockwerke ausbreitet, was sowohl
                        die Gefahr für Menschen als auch das Ausmaß des Schadens
                        vergrößert. Eine Brandmeldeanlage ist aber nur dann
                        wirksam, wenn sie richtig installiert ist und über
                        wesentliche Zertifizierungen verfügt. MeinAlarm24
                        verbaut ausschließlich Brandwarnanlagen, die von
                        professionellen Herstellern bezogen werden. Dabei sind
                        alle einzelnen Brand- und Rauchmelder nach DIN EN 14604
                        zertifiziert. Unsere zertifizierten Sicherheitstechniker
                        werden regelmäßig geschult und übernehmen die
                        Installation, Wartung und Inbetriebnahme der
                        Brandwarnanlage für Sie.
                        <h3>Privater und betrieblicher Brandschutz</h3>
                        Wir bieten Ihnen eine Brandmeldeanlage für Ihr Zuhause,
                        aber auch für kleine bis mittlere Unternehmen und große
                        Konzerne. Wenn Sie bereits über Sicherheitstechnik
                        verfügen und wissen wollen, ob zusätzliche Maßnahmen
                        erforderlich sind, können Sie eine Sicherheitsinspektion
                        vor Ort mit einem professionellen Sicherheitsberater
                        durchführen lassen.
                    </div>
                ) : (
                    ""
                )}

                <SevenDaysBanner cssclassName="with-background" city={city} />
                <Footer />
            </div>
        </div>
    );
};

export const query = graphql`
    query ContentPageQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                image
                image768
                image1024
                image1216
                image1408
                hero
            }
        }
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
            }
        }
    }
`;
