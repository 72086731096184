import React from 'react';
import PriceCalculationButton from './PriceCalculator/PriceCalculationButton';

export default class OurFavoritsVideo extends React.Component {
    render() {
        const slug = typeof this.props.city !== 'undefined' ? this.props.city.slug : this.props.city;
        return (
            <div className="section our-favorits-video">
                <div className="has-text-centered"><h2 className="title">Die Favoriten von MeinAlarm24</h2></div>
                <div className="columns is-multiline">
                    <div className="column left-column">
                        <div className="title">
                            <div className="top"><img src="/img/dome-kamera-dahua.png" alt="Dahua Dome Kamera" title="Dahua Dome Kamera"/></div>
                            <div className="middle"></div>
                            <div className="bottom">
                                <PriceCalculationButton text='Was kostet eine Dome Kamera?' city={slug} name='product' value='Dahua Dome Kamera'/>
                            </div>
                        </div>
                    </div>
                    <div className="column right-column">
                        <div className="title">
                            <div className="top"><img src="/img/bullet-kamera-dahua-2.png" alt="Dahua Bullet Kamera" title="Dahua Bullet Kamera"/></div>
                            <div className="middle"></div>
                            <div className="bottom">
                                <PriceCalculationButton text='Was kostet eine Bullet Kamera?' city={slug} name='product' value='Dahua Bullet Kameras'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
