import React from "react";
import { Link } from "gatsby";

const BrandmeldanlagenHero = () => (
    <div className="hero_brandanlage columns">
        <div className="column left">
            <div className="is-hidden-mobile">
                <h1 className="title">Unsere Brandmeldeanlagen</h1>
            </div>
            <div className="is-hidden-tablet">
                <h1 className="title">
                    Unsere Brandmelde-
                    <br />
                    anlagen
                </h1>
            </div>
            <div className="subtitle">
                <p>Erkennt die Gefahr.</p>
                <p>Alarmiert.</p>
                <p>Rettet Leben!</p>
                <div className="cta-btn is-hidden-mobile">
                    <Link
                        id="btn_bigheroWP_preisberechnen"
                        className="button preisrechner-btn preisrechner-btn-primary"
                        to="/brandmeldeanlagen/preisrechner"
                    >
                        Kostenlos Preis berechnen
                    </Link>
                </div>
            </div>
            <div className="bottom only-mobile has-text-centered">
                <Link
                    id="btn_bigheroWP_preisberechnen"
                    className="button preisrechner-btn preisrechner-btn-primary"
                    to="/brandmeldeanlagen/preisrechner"
                >
                    Kostenlos Preis berechnen
                </Link>
            </div>
        </div>
        <div className="column right is-hidden-touch">
            <div className="columns">
                <div className="column central-img app is-vertical-bottom is-narrow">
                    <img
                        src="/img/brandmeldezentrale_meinalarm24.png"
                        alt="Brandmeldeanalage Zentrale"
                    />
                </div>
                <div className="column bma-img is-narrow">
                    <img
                        src="/img/MeinAlarm24_Hekatron_Brandmeldeanlage.png"
                        alt="Brandmeldeanlage Hekatron"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default BrandmeldanlagenHero;
