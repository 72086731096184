import React from "react";
import PhonePicture from "../../img/phone2_blue.svg";

const EinlasskontrollsystemeHero = ({ city }) => (
    <div className="hero_fieber-kameras columns">
        <div className="column left">
            <h1 className="title is-hidden-mobile">
                Unsere Einlasskontrollsysteme
            </h1>
            <h1 className="title is-hidden-tablet is-size-2">
                Unsere Einlasskontroll-systeme
            </h1>
            <div className="subtitle is-size-3-mobile">
                <p>Präzise Personenzählung.</p>
                <p>Höchste Kosteneinsparung.</p>
                <p>Gegen die Corona Pandemie.</p>
                <div className="phone ">
                    <div className="column">
                        <div className="text is-size-5-mobile">
                            Jetzt kostenfrei beraten lassen
                        </div>
                        <div className="phone-number columns is-inline-block">
                            <PhonePicture className="logo" />
                            <a
                                id="btn_bigheroWP_phone"
                                href={"tel://0800 150 99 00"}
                            >
                                0800 150 99 00
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default EinlasskontrollsystemeHero;
