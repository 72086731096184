import React from "react";
import { Link } from "gatsby";
import GoogleAnalytics from "../../helper/GoogleAnalytics";

const onLinkClick = () => {
    console.log("link clicked");
    //  window.dataLayer.push({
    //      event: eventAction,
    //      send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
    //      event_category: eventCategory,
    //      event_label: eventLabel,
    //  });
    let ga = new GoogleAnalytics();
    ga.sendGTMEvent("custom_event_test", "/tuerschloesser");
    console.log("event is fired");
};
const TuerschloesserHero = ({ city }) => (
    <div className="hero_tuerschloesser columns">
        <div className="column left">
            <h1 className="title">Unsere elektronischen Türschlösser</h1>
            <div className="subtitle">
                <p>Sicher schliessen.</p>
                <p>Smart bedienbar.</p>
                <p>100% Zutrittskontrolle.</p>
                <div className="cta-btn is-hidden-mobile" onClick={onLinkClick}>
                    {/* Dont remove id its used to track GTM events */}
                    <Link
                        id="btn_bigheroWP_preisberechnen"
                        className="button preisrechner-btn preisrechner-btn-primary"
                        to="/schliessanlagen/preisrechner"
                    >
                        Kostenlos Preis berechnen
                    </Link>
                </div>
            </div>
            <div className="bottom only-mobile has-text-centered">
                <Link
                    id="btn_bigheroWP_preisberechnen"
                    className="button preisrechner-btn preisrechner-btn-primary"
                    to="/schliessanlagen/preisrechner"
                >
                    Kostenlos Preis berechnen
                </Link>
            </div>
        </div>
        <div className="column right is-hidden-touch">
            <img src="/img/Türschloss.png" alt="Elektronisches Türschlösser" />
        </div>
    </div>
);

export default TuerschloesserHero;
