import React from "react";
import PhonePicture from "../../img/phone2_blue.svg";

const FieberKamerasHero = ({ city }) => (
    <div className="hero_fieber-kameras columns">
        <div className="column left">
            <h1 className="title">Unsere Fieber-Kameras</h1>
            <div className="subtitle">
                <p>Messen hochpräzise.</p>
                <p>Schaffen höchste Effizienz.</p>
                <p>Gegen die Corona Pandemie.</p>
                <div className="phone ">
                    <div className="column">
                        <div className="text">
                            Jetzt kostenfrei beraten lassen
                        </div>
                        <div className="phone-number columns is-inline-block">
                            <PhonePicture className="logo" />
                            <a
                                href={"tel://0800 150 99 00"}
                                id="btn_bigheroWP_phone"
                            >
                                0800 150 99 00
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default FieberKamerasHero;
