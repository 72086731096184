import React from 'react';

const Einlasskontrollsysteme = ({city}) => {
    let citySlug = typeof city !== 'undefined' ? city.slug : '';
    return (
        <div className="section fieber-kameras">
            <h2 className="has-text-centered ">Die Vorteile mit unseren Einlasskontrollsystemen</h2> 
            <ul className="checkmark centered-content">
                <li>
                    <span className="top">Automatische Ampelschaltung</span>
                    <p>Wird die vorgegebene Personenzahl überschritten, schaltet der Monitor für neue Besucher automatisch auf rot. Zusätzlich kann das System bei Überschreitung auch ein akustisches Zeichen abgeben - damit Sie auf Nummer sicher gehen.</p>
                </li>
                <li>
                    <span className="top">Kosteneinsparung durch weniger Personal</span>
                    <p>Sowohl die Personenzählung als auch die Ampelschaltung bei Überschreitung einer vorgegebenen Personenzahl erfolgt komplett automatisch. Ersetzen Sie das kostspielige Personal durch modernste Technik.</p>
                </li>
                <li>
                    <span className="top">Vielseitige Anwendungsbereiche</span>
                    <p>Unsere Einlasskontroll-Systeme werden u.a. eingesetzt in Supermärkten, Drogerien, Apotheken, Ladengeschäften, Shopping-Center, Kaufhäuser, Baumärkte, Autohäuser, Restaurants, Betriebskantinen und vielen mehr.</p>
                </li>
                <li>
                    <span className="top">Echtzeit-Anzeige und weitere Analysen</span>
                    <p>Bleiben Sie immer auf dem aktuellsten Stand! Neben einer Anzeige zu der aktuellen Personenzählung, lassen sich auch weitere Analysen fahren, zum Beispiel zum Gefährlichkeitsgrad der Menschenmenge.</p>
                </li>
                <li>
                    <span className="top">100% DSGVO-konform</span>
                    <p>Es werden bei der Personenzählung durch unsere Systeme keine personenbezogenen Daten gespeichert. Die Personenzählung erfolgt DSGVO-konform.</p>
                </li>
                <li>
                    <span className="top">Kontrolle auf Mund-Nasenmasken</span>
                    <p>Sorgen Sie für maximale Sicherheit während der Corona Pandemie. Unser Einlasskontroll-System erkennt sogar ob die Personen eine Mund-Nasenmaske tragen (optional).</p>
                </li>
            </ul>
        </div>
    );
};


export default Einlasskontrollsysteme;