import React from "react";
import PriceCalculationButton from "../PriceCalculator/PriceCalculationButton";

const VideoueberwachungHero = () => (
    <div className="hero_video columns">
        <div className="column left">
            <h1 className="title">Unsere Videoanlagen</h1>
            <div className="subtitle">
                <p>Smart.</p>
                <p>Professionell.</p>
                <p>Ultra HD.</p>
                <div className="cta-btn is-hidden-mobile">
                    <PriceCalculationButton/>
                </div>
            </div>
            <div className="bottom only-mobile has-text-centered">
                <PriceCalculationButton/>
            </div>
        </div>
        <div className="column right is-hidden-touch">
            <div className="columns">
                <div className="column app is-vertical-bottom is-narrow">
                    <img src="/img/videoanlage-und-smartphone.png" alt="Smart Home und Smartphone"/>
                </div>
                <div className="column videoueberwachung is-narrow">
                    <img src="/img/bullet-kamera-dahua.png" alt="Dahua Kamera"/>
                </div>
            </div>
        </div>
    </div>
);

export default VideoueberwachungHero;
