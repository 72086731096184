import React from "react";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import { Brand } from "./PriceCalculator/elements/Constants";

const Brandmeldeanlagen = ({ city }) => {
    let citySlug = typeof city !== "undefined" ? city.slug : "";
    return (
        <div className="section brandmeldeanlagen">
            <h2 className="has-text-centered">
                Die Vorteile unserer Brandmeldeanlagen
            </h2>
            <ul className="checkmark centered-content">
                <li>
                    <span className="top">Rundum-Sorglos-Service</span>
                    <p>
                        Neben der Konzeptionierung und Installation übernehmen
                        wir auch die Inebtriebnahme und die Wartung ihrer
                        Brandmeldeanlage. Lehnen Sie sich zurück!
                    </p>
                </li>
                <li>
                    <span className="top">Individuelle Projektierung</span>
                    <p>
                        Wir tragen Sorge dafür, dass die Brandmeldeanlage den
                        baulichen und versicherungstechnischen Anforderungen
                        entspricht. Auch bei Modernisierungen finden wir immer
                        die richtige Lösung.
                    </p>
                </li>
                <li>
                    <span className="top">
                        Schnellster Schutz dank 24/7 Notrufleitstelle
                    </span>
                    <p>
                        Unsere Brandmeldeanlagen werden standardmäßig auf eine
                        Notrufleitstelle aufgeschaltet. So wird im Brandfall
                        auch die Feuerwehr alarmiert.
                    </p>
                </li>
                <li>
                    <span className="top">
                        Hauptansprechpartner für Brandschutz
                    </span>
                    <p>
                        Wir übernehmen bei Bedarf die Kommunikation mit
                        Architekten, Ingenieurbüros oder Bauherren - auch wenn
                        es komplizierter wird.
                    </p>
                </li>
                <li>
                    <span className="top">Modernste Technik</span>
                    <p>
                        Unsere Brandmeldeanlagen weisen neben einer
                        zertifizierten Technik und einer Vielzahl an
                        Steuerungsfunktionen auch den höchsten Bedienkomfort
                        auf.
                    </p>
                </li>
            </ul>
            <div className="has-text-centered">
                <PriceCalculationButton
                    id="btn_ vorteilesystcat_mehrerfahren"
                    text="Mehr erfahren"
                    city={citySlug}
                    product={Brand}
                />
                <br />
            </div>
        </div>
    );
};

export default Brandmeldeanlagen;
